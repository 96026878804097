<template>
  <div class="card-legend">
    <div class="card-legend-header">
      <span :class="badgeClass" v-text="status"></span>
    </div>
    <div class="card-legend-content">
      <span class="text-description" v-html="text"></span>
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    status: {
      type: String,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    badgeClass() {
      const badgeClass = `badge badge-${this.color}`;
      return `${badgeClass}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-legend {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  transition: 0.5s;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  margin: 1em 0;
}
.card-legend-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.875em 0;
}
</style>
