<template>
  <div>
    <nav-bar></nav-bar>
    <div class="main">
      <div class="paths">
        <router-link
          :style="linkColor('profile-main')"
          class="link"
          to="/profile"
        >
          Dados Pessoais
        </router-link>
        <!--        <router-link class="link" to="/address-profile">-->
        <!--          Meu Endereço-->
        <!--        </router-link>-->
        <router-link
          :style="linkColor('indications')"
          class="link"
          to="/indications"
        >
          Minhas indicações
        </router-link>
        <router-link
          :style="linkColor('payment')"
          class="link"
          to="/payment"
          style="color: red"
        >
          E-mail para Pagamento
        </router-link>
        <router-link :style="linkColor('leave')" class="link" to="/leave">
          Desativar Conta
        </router-link>
      </div>
      <router-view />

      <div class="legend">
        <summary-status status="Sobre seus dados" color="info">
          <div slot="body">
            <p>
              Caso tenha dúvidas, consulte nossas
              <a
                target="_blank"
                href="https://ilumeo-brasil-com-br.s3.sa-east-1.amazonaws.com/politica-de-privacidade-inside-brasil.pdf"
              >
                <b>Políticas de Privacidade</b>
              </a>
              e nossos
              <a
                target="_blank"
                href="https://ilumeo-brasil-com-br.s3.sa-east-1.amazonaws.com/termos-e-condicoes-de-uso-inside+brasil.pdf"
              >
                <b>Termos de Uso</b>
              </a>
            </p>
          </div>
        </summary-status>
      </div>
      <div class="legend">
        <summary-status
          color="light-warning"
          status="Importante"
          text="Mantenha os dados atualizados no seu perfil para receber pesquisas"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue";
import SummaryStatus from "@/components/SummaryStatus/index.vue";

export default {
  components: {
    NavBar,
    SummaryStatus,
  },
  methods: {
    linkColor(path = "/") {
      if (this.currentRoute === path) return "color: var(--primary);";

      return "";
    },
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.paths {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  overflow: auto;
}
.main {
  padding: 2rem 1.1em;
  max-width: 1200px;
  margin: 0 auto;
}
.legend {
  padding: 1em;
}
.hyperlink {
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: var(--primary);
}
</style>
